// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react'
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Context
import { useAuth } from 'src/hooks/useAuth'

// Development Imports
import CustomAvatar from 'src/@core/components/mui/avatar'
import { getInitials } from 'src/@core/utils/get-initials'
import { ThemeColor } from 'src/@core/layouts/types'
import UserMenu from './UserMenu'

// Hooks
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = () => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const { settings } = useSettings()
  const router = useRouter()
  const { user } = useAuth()

  // ** Vars
  const { direction } = settings
  const fullName = `${user?.fullName()}` || ''

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Box sx={{ pb: 4, px: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleDropdownOpen}>
          <Badge
            overlap='circular'
            sx={{ ml: 4, mr: 2 }}
            badgeContent={<BadgeContentSpan />}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CustomAvatar
              skin='light'
              variant='circular'
              color={'primary' as ThemeColor}
              sx={{ width: '2.5rem', height: '2.5rem', fontWeight: 600 }}
            >
              {getInitials(fullName)}
            </CustomAvatar>
          </Badge>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 600 }}>{`${user?.fullName()}`}</Typography>
            <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
              {user?.tenant ?? 'Dashbi'}
            </Typography>
          </Box>
        </Box>
        <UserMenu anchorEl={anchorEl} handleDropdownClose={handleDropdownClose} direction={direction}></UserMenu>
      </Box>
    </Fragment>
  )
}

export default UserDropdown
